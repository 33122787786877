import React from "react"

import Layout from "~components/layout"
import SEO from "~components/seo"
import UseCaseHero from "~content/use-case/Hero"
import UseCaseServicing from "~content/use-case/Servicing"
import UseCaseIndustries, { defaultIndustriesList } from "~content/use-case/Industries"
import Community from "~content/use-case/Community"

import heroImage from "~images/use-case/multiple-product-constructs-hero.png"

const UseCaseMultiAdvanceInstallmentPage: React.FC = () => {
  return (
    <Layout>
      <SEO
        canonicalTag="https://canopyservicing.com/use-case/next-gen-constructs"
        description="Design new lending products with more than 20+ attributes and service them in a secure and compliant way."
        title="Next-gen Constructs | Canopy Servicing"
      />
      <UseCaseHero
        title="Next-gen Constructs"
        body="Grow your lending products by adding credit solutions along with debit offerings."
        image={heroImage}
      />
      <UseCaseServicing
        bullet1="Real-time calculations"
        bullet2="Event-driven automations"
        bullet3="Pre-built popular integrations"
      />
      <UseCaseIndustries industries={defaultIndustriesList} />
      <Community />
    </Layout>
  )
}

export default UseCaseMultiAdvanceInstallmentPage
